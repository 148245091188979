import {get, head, toString, map, concat, intersection, compact, values, pick, replace} from 'lodash-es';
import {shortName} from '@/store/inspect/selectors';

export const userList = (result) => {
  return {
    navCount: get(result, 'navCount'),
    navPage: get(result, 'navPage'),
    navPageCount: get(result, 'navPageCount'),
    navPageSize: get(result, 'navPageSize'),
    items: map(get(result, 'items'), (item) => ({
      id: toString(get(item, 'id')),
      lastName: toString(get(item, 'lastName')),
      name: toString(get(item, 'name')),
      fio: shortName(item),
      secondName: toString(get(item, 'secondName')),
      phone: toString(get(item, 'phone')),
      reviewerComment: toString(get(item, 'reviewerComment')),
      address: toString(get(item, 'address')),
      companyInn: toString(get(item, 'companyInn')),
      companyName: toString(get(item, 'companyName')),
      companyOgrn: toString(get(item, 'companyOgrn')),
      email: toString(get(item, 'email')),
    })),
  }
};

export const userItem = (item) => {
  const name = get(item, 'name', '');
  const second = get(item, 'secondName', '');
  const last = get(item, 'lastName', '');
  const isEvrazEmployee = get(item, 'isEvrazEmployee', false);
  let short = last;
  if (name) short += ` ${head(name)}.`;
  if (second) short += `${head(second)}.`;
  return {
    id: toString(get(item, 'id')),
    name,
    second,
    last,
    short,
    isEvrazEmployee,
    account: {
      id:  toString(get(item, 'account.applicationId', null)),
      accountId: toString(get(item, 'account.accountId', null)),
      status: get(item, 'account.status'),
      accounts: map(get(item, 'account.accounts', []), (item) => ({
        id: toString(get(item, 'id')),
        valuesVersion: {
          id: toString(get(item, 'valuesVersion.id')),
          type: {
            code: toString(get(item, 'valuesVersion.type.code')),
            title: toString(get(item, 'valuesVersion.type.title'))
          },
          companyNameFull: toString(get(item, 'valuesVersion.companyNameFull')),
          companyNameShort: toString(get(item, 'valuesVersion.companyNameShort')),
        },
      })),
      availableTypes: map(get(item, 'account.availableTypes', []), ({ code, name }) => ({
        value: toString(code),
        text: toString(name),
      })),
    },
    redirect: get(item, 'redirectUrl'),
    masterUser: {
      id: toString(get(item, 'masterUser.id')),
      name: shortName(get(item, 'masterUser')),
    },
    company: {
      inn: toString(get(item, 'companyInn')),
      name: toString(get(item, 'companyName')),
    },
    role: {
      'account-users': {
        code: get(item, 'account.role.code'),
        name: get(item, 'account.role.name'),
      },
      account: {
        code: get(item, 'account.role.code'),
        name: get(item, 'account.role.name'),
      },
      elkp: {
        code: toString(get(item, 'elkpRole.code')),
        name: toString(get(item, 'elkpRole.name')),
      },
      pkopir: {
        code: toString(get(item, 'pkoPirRole.code')),
        name: toString(get(item, 'pkoPirRole.name')),
      },
      pkosmr: {
        code: toString(get(item, 'pkoSmrRole.code')),
        name: toString(get(item, 'pkoSmrRole.name')),
        options: map(get(item, 'pkoSmrRole.options'), ({ name, code }) => ({
          text: name,
          value: code,
        }))
      },
      pkopnr: {
        code: toString(get(item, 'pkoPnrRole.code')),
        name: toString(get(item, 'pkoPnrRole.name')),
        limitReached: !!get(item, 'pkoPnrRole.limitReached'),
        options: map(get(item, 'pkoPnrRole.options'), ({ name, code }) => ({
          text: name,
          value: code,
        }))
      },
      pkomain: {
        code: toString(get(item, 'pkoMainRole.code')),
        name: toString(get(item, 'pkoMainRole.name')),
      },
      inspect: {
        code: toString(get(item, 'inspectRole.code')),
        name: toString(get(item, 'inspectRole.name')),
        master: get(item, 'masterUser'),
        options: map(get(item, 'inspectRole.options'), ({ name, code }) => ({
          text: name,
          value: code,
        }))
      },
      spa: {
        code: toString(get(item, 'spaRole.code')),
        name: toString(get(item, 'spaRole.name')),
        options: map(get(item, 'spaRole.options'), ({ name, code }) => ({
          text: name,
          value: code,
        })),
        authLink: toString(get(item, 'spaRole.authLink')),
      },
      docs: {
        code: toString(get(item, 'documentsRole.code')),
        name: toString(get(item, 'documentsRole.name')),
        options: map(get(item, 'documentsRole.options'), ({ name, code }) => ({
          text: name,
          value: code,
        })),
        message: toString(get(item, 'documentsRole.message')),
      }
    }
  }
}

export const userProfile = (result) => {
  const dictionary = get(result, 'dictionary');
  const item = get(result, 'item');
  const rules = {
    required:  { reg: '.+', text: 'Обязательное поле' },
    email: { reg: '^[A-zА-я0-9_.+-]+@[A-zА-я0-9-]+\\.[A-zА-я0-9-.]+$', text: 'Укажите корректный email' },
    inn: { reg: '^\\d{10}(\\d{2})?$|^$', text: 'Укажите корректный ИНН' },
    nine: { reg: '^\\d{9}?$|^$', text: 'состоит из 9 цифр' },
    twelve: { reg: '^\\d{12}?$|^$', text: 'состоит из 12 цифр' },
    ogrn: { reg: '^\\d{13}(\\d{2})?$|^$', text: 'Укажите корректный ОГРН' },
    twenty: { reg: '^\\d{20}?$|^$', text: 'состоит из 20 цифр' },
    phone: [
      (value) => /[+7] \(\d{3}\) \d{3} \d{2} \d{2}/.test(value) || 'Укажите корректный телефон',
    ],
    address: {
      text: 'Укажите корректный адрес',
      callback: (data) => {
        return !(new RegExp('#{4,}')).test(values(pick(data, ['country', 'region', 'zipCode', 'city', 'street', 'house'])).join('###') + '###');
      }
    },
    person: {
      text: 'Заполните все поля',
      callback: (data) => {
        return !(new RegExp('#{4,}')).test(values(data).join('###') + '###');
      }
    },
  };
  return {
    captcha: get(result, 'captchaImg'),
    masterUser: {
      id: toString(get(item, 'MASTER_USER.id')),
      name: shortName(get(item, 'MASTER_USER')),
      email: toString(get(item, 'MASTER_USER.email')),
      companyName: toString(get(item, 'MASTER_USER.companyName')),
      companyInn: toString(get(item, 'MASTER_USER.companyInn')),
    },
    settings: {
      maxSlaveUsersCount: get(result, 'settings.maxSlaveUsersCount', 0),
    },
    values: {
      PASSWORD: {
        label: 'Текущий пароль',
        values: [
          get(item, 'PASSWORD', ''),
        ],
      },
      NEW_PASSWORD: {
        label: 'Новый пароль',
        values: [
          get(item, 'NEW_PASSWORD', ''),
        ],
      },
      NEW_PASSWORD_DUPLICATE: {
        label: 'Подтверждение пароля',
        values: [
          get(item, 'NEW_PASSWORD_DUPLICATE', ''),
        ],
      },
      LAST_NAME: {
        label: 'Фамилия',
        rules: [ rules.required ],
        values: [
          get(item, 'LAST_NAME', ''),
        ],
      },
      NAME: {
        label: 'Имя',
        rules: [ rules.required ],
        values: [
          get(item, 'NAME', '')
        ],
      },
      SECOND_NAME: {
        label: 'Отчество',
        rules: [ rules.required ],
        values: [
          get(item, 'SECOND_NAME', '')
        ],
      },
      EMAIL: {
        label: 'E-mail ответственного лица',
        rules: [ rules.email ],
        values: [
          get(item, 'EMAIL')
        ],
      },
      PERSONAL_PHONE: {
        label: 'Телефон ответственного лица',
        type: 'tel',
        rules: [ rules.required, rules.phone ],
        mask: '+7 (###) ### ## ##',
        values: [
          get(item, 'PERSONAL_PHONE', '')
        ],
      },
      UF_EXTRA_MAIL: {
        label: 'Дополнительный E-mail',
        rules: [ rules.email ],
        values: get(item, 'UF_EXTRA_MAIL', []),
      },
      UF_FIRST_GEN_CONTACT: {
        label: 'Первый руководитель организации',
        rules: [ rules.required, rules.person ],
        values: [
          get(item, 'UF_FIRST_GEN_CONTACT'),
        ],
      },
      UF_CONTACT_PERSONS: {
        label: 'Дополнительное контактное лицо',
        values: get(item, 'UF_CONTACT_PERSONS', []),
      },
      UF_USER_TYPE: {
        label: 'Форма организации',
        options: get(dictionary, 'UF_USER_TYPE'),
        values: [
          toString(get(item, 'UF_USER_TYPE')),
        ],
      },
      UF_FULLNAME: {
        label: 'Полное наименование организации (как в Уставе) или Фамилия Имя Отчество (для ИП)',
        type: 'autocomplete',
        rules: [ rules.required ],
        values: [
          get(item, 'UF_FULLNAME', ''),
        ],
      },
      UF_SHORTNAME: {
        label: 'Краткое наименование организации (как в Уставе) или Фамилия И.О. (для ИП)',
        type: 'autocomplete',
        rules: [ rules.required ],
        values: [
          get(item, 'UF_SHORTNAME', ''),
        ],
      },
      UF_PASSPORT_NUMBER: {
        label: 'Серия и номер паспорта',
        mask: '## ## ######',
        rules: [ rules.required ],
        values: [
          get(item, 'UF_PASSPORT_NUMBER', ''),
        ],
      },
      UF_PASSPORT_DATE: {
        label: 'Дата выдачи паспорта',
        rules: [ rules.required ],
        type: 'date',
        max: new Date().toISOString().slice(0, 10),
        values: [
          get(item, 'UF_PASSPORT_DATE', ''),
        ],
      },
      UF_INN: {
        label: 'ИНН',
        rules: [ rules.required, rules.inn ],
        mask: '############',
        values: [
          get(item, 'UF_INN', ''),
        ],
      },
      UF_TAX_ID: {
        label: 'TAX ID',
        rules: [ rules.required ],
        values: [
          get(item, 'UF_TAX_ID', ''),
        ],
      },
      UF_KPP: {
        label: 'КПП',
        rules: [ rules.required, rules.nine ],
        type: 'autocomplete',
        mask: '#########',
        values: [
          get(item, 'UF_KPP', ''),
        ],
      },
      UF_OGRN: {
        label: 'ОГРН',
        rules: [ rules.required, rules.ogrn ],
        type: 'autocomplete',
        mask: '###############',
        values: [
          get(item, 'UF_OGRN', ''),
        ],
      },
      UF_CAN_INSPECT: {
        label: 'Инспекционная деятельность',
        values: [
          !!get(item, 'UF_CAN_INSPECT', 0),
        ],
      },
      UF_JUR_ADRESS_JSON: {
        label: 'Юридический адрес или адрес регистрации для ИП или физического лица',
        rules: [ rules.required, rules.address ],
        hint: get(item, 'UF_JUR_ADRESS'),
        description: 'Фактический адрес совпадает с юридическим',
        values: [
          get(item, 'UF_JUR_ADRESS_JSON')
        ],
      },
      UF_FACT_ADRESS_JSON: {
        label: 'Фактический адрес',
        rules: [ rules.required, rules.address ],
        hint: get(item, 'UF_FACT_ADRESS'),
        description: 'Почтовый адрес совпадает с фактическим',
        values: [
          get(item, 'UF_FACT_ADRESS_JSON')
        ],
      },
      UF_POST_ADRESS_JSON: {
        label: 'Почтовый адрес',
        rules: [],
        hint: get(item, 'UF_POST_ADRESS'),
        description: '',
        values: [
          get(item, 'UF_POST_ADRESS_JSON')
        ],
      },
      UF_PROJ_SERVICES: {
        label: 'Выполнение Работ и Услуг',
        values: [
          !!get(item, 'UF_PROJ_SERVICES', 0),
        ],
      },
      UF_WORK_WITH_TMC: {
        label: 'Поставка ТМЦ и оборудования',
        values: [
          !!get(item, 'UF_WORK_WITH_TMC', 0),
        ],
      },
      UF_PROJ_ILLQ_TEND: {
        label: 'Приобретение Неликвидов',
        values: [
          !!get(item, 'UF_PROJ_ILLQ_TEND', 0),
        ],
      },
      UF_COMPANU: {
        label: 'Предприятия ЕВРАЗ, с которыми организация готова работать',
        options: get(dictionary, 'UF_COMPANU'),
        values: get(item, 'UF_COMPANU'),
      },
      UF_ACTIVITY: {
        label: 'Основные виды выполняемых Работ и Услуг (максимум 24 шт.) Подписка на Оповещения о новых закупочных процедурах.',
        options: get(dictionary, 'UF_ACTIVITY'),
        values: intersection(get(item, 'UF_ACTIVITY'), map(get(dictionary, 'UF_ACTIVITY'), 'value')),
      },
      UF_ACTIVITY_TMC: {
        label: 'Виды ТМЦ, оборудования',
        options: get(dictionary, 'UF_ACTIVITY_TMC'),
        values: intersection(get(item, 'UF_ACTIVITY'), map(get(dictionary, 'UF_ACTIVITY_TMC'), 'value')),
      },
      UF_TYPE_WORK: {
        label: 'Виды работ и услуги, подписка',
        options: get(dictionary, 'UF_ACTIVITY'),
        values: intersection(get(item, 'UF_TYPE_WORK'), map(get(dictionary, 'UF_ACTIVITY'), 'value')),
      },
      UF_TYPE_WORK_TMC: {
        label: 'Виды ТМЦ, оборудования, подписка',
        options: get(dictionary, 'UF_ACTIVITY_TMC'),
        values: intersection(get(item, 'UF_TYPE_WORK'), map(get(dictionary, 'UF_ACTIVITY_TMC'), 'value')),
      },
      UF_ARTICLES_OF_ASSOC: {
        label: 'Устав предприятия в полном объеме',
        values: compact([
          get(item, 'UF_ARTICLES_OF_ASSOC'),
        ])
      },
      UF_EGRUL: {
        label: 'Выписка из ЕГРЮЛ',
        values: compact([
          get(item, 'UF_EGRUL'),
        ])
      },
      UF_ELECTION_PROTOCOL: {
        label: 'Протокол собрания о назначении руководителя предприятия',
        values: compact([
          get(item, 'UF_ELECTION_PROTOCOL'),
        ])
      },
      UF_NALOG_CERT: {
        label: 'Свидетельство о постановке на учет в налоговом органе',
        values: compact([
          get(item, 'UF_NALOG_CERT'),
        ])
      },
      UF_OGRN_LIC: {
        label: 'Свидетельство о регистрации (ОГРН)',
        values: compact([
          get(item, 'UF_OGRN_LIC'),
        ])
      },
      UF_DIRECTOR_ORDER: {
        label: 'Приказ о назначении директора',
        values: compact([
          get(item, 'UF_DIRECTOR_ORDER'),
        ])
      },
      UF_LICEN_METALL: {
        label: 'Документ, необходимый для допуска к лотам цветного и легированного металлолома - лицензия на осуществление заготовки, переработки и реализации лома черных и/или цветных металлов',
        values: compact([
          get(item, 'UF_LICEN_METALL'),
        ])
      },
      UF_LICEN_WASTE_1_4: {
        label: 'Документ, необходимый для допуска к лотам, содержащим отходы меди - лицензия по обращению с отходами I-IV классов опасности (включая все приложения)',
        values: compact([
          get(item, 'UF_LICEN_WASTE_1_4'),
        ])
      },
      UF_LIC_WASTE_1_4_AKB: {
        label: 'Документ, необходимый для допуска к лотам, содержащим лом аккумуляторных батарей - лицензия по обращению с отходами I-IV классов опасности (включая все приложения)',
        values: compact([
          get(item, 'UF_LIC_WASTE_1_4_AKB'),
        ])
      },
      UF_SPEC_RECORD: {
        label: 'Документ, необходимый для допуска к лотам, содержащим лом и отходы драгоценных металлов - уведомление о постановке на спец. учет',
        values: compact([
          get(item, 'UF_SPEC_RECORD'),
        ])
      },
      UF_APPLICATION_FORM: {
        label: 'Документ, необходимый для допуска к лотам, содержащим лом и отходы драгоценных металлов - анкета контрагента  <a href="/upload/%D0%90%D0%BD%D0%BA%D0%B5%D1%82%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%82%D1%80%D0%B0%D0%B3%D0%B5%D0%BD%D1%82%D0%B0%20(%D0%B4%D0%BB%D1%8F%20%D0%B7%D0%B0%D0%BF%D0%BE%D0%BB%D0%BD%D0%B5%D0%BD%D0%B8%D1%8F).docx">Скачать шаблон анкеты</a>',
        values: compact([
          get(item, 'UF_APPLICATION_FORM'),
        ])
      },
      UF_EVRAZ_PD_AGREE: {
        label: 'Соглашаюсь на обработку персональных данных АО "ЕВРАЗ"',
        values: [
          !!get(item, 'UF_EVRAZ_PD_AGREE', 0),
        ],
      },
      BANK_DETAILS: {
        label: 'Дополнительные банковские реквизиты',
        values: get(item, 'BANK_DETAILS', []),
      },
      SLAVE_USERS: {
        label: 'Сотрудники контрагента',
        options: get(dictionary, 'ROLES'),
        values: get(item, 'SLAVE_USERS', []),
      },
      ROLES: {
        label: 'Роли',
        options: get(dictionary, 'ROLES'),
        values: get(item, 'ROLES', []),
      },
      CAN_ADD_SLAVES: {
        label: 'Можно ли добавлять подчиненных сотрудников',
        values: get(item, 'CAN_ADD_SLAVES', false),
      },
    },
  }
};
export const userProfilePayload = (values) => ({
  item: {
    // "ID": 1,
    PASSWORD: get(values,['PASSWORD', 'values', 0], null),
    NEW_PASSWORD: get(values,['NEW_PASSWORD', 'values', 0], null),
    NEW_PASSWORD_DUPLICATE: get(values,['NEW_PASSWORD_DUPLICATE', 'values', 0], null),
    LAST_NAME: get(values,['LAST_NAME', 'values', 0], null),
    NAME: get(values,  ['NAME', 'values', 0], null),
    SECOND_NAME: get(values,['SECOND_NAME', 'values', 0], null),
    EMAIL: get(values,['EMAIL', 'values', 0], null),
    PERSONAL_PHONE: get(values,['PERSONAL_PHONE', 'values', 0], null),
    UF_EXTRA_MAIL: get(values,['UF_EXTRA_MAIL', 'values'], []),
    UF_FIRST_GEN_CONTACT: get(values,['UF_FIRST_GEN_CONTACT', 'values', 0]),
    UF_CONTACT_PERSONS: get(values,['UF_CONTACT_PERSONS', 'values'], []),
    UF_USER_TYPE: get(values,['UF_USER_TYPE', 'values', 0], null),
    UF_FULLNAME: get(values,['UF_FULLNAME', 'values', 0], null),
    UF_SHORTNAME: get(values,['UF_SHORTNAME', 'values', 0], null),
    UF_PASSPORT_NUMBER: replace(get(values,['UF_PASSPORT_NUMBER', 'values', 0], null), /\s/g, ''),
    UF_PASSPORT_DATE: replace(get(values,['UF_PASSPORT_DATE', 'values', 0], null), /\s/g, ''),
    UF_INN: get(values,['UF_INN', 'values', 0], null),
    UF_TAX_ID: get(values,['UF_TAX_ID', 'values', 0], null),
    UF_KPP: get(values,['UF_KPP', 'values', 0], null),
    UF_OGRN: get(values,['UF_OGRN', 'values', 0], null),
    UF_CAN_INSPECT: get(values, ['UF_CAN_INSPECT', 'values', 0], null),
    UF_JUR_ADRESS_JSON: get(values,['UF_JUR_ADRESS_JSON', 'values', 0]),
    UF_FACT_ADRESS_JSON: get(values,['UF_FACT_ADRESS_JSON', 'values', 0]),
    UF_POST_ADRESS_JSON: get(values,['UF_POST_ADRESS_JSON', 'values', 0]),
    UF_PROJ_SERVICES: get(values,['UF_PROJ_SERVICES', 'values', 0], null),
    UF_WORK_WITH_TMC: get(values,['UF_WORK_WITH_TMC', 'values', 0], null),
    UF_PROJ_ILLQ_TEND: get(values,['UF_PROJ_ILLQ_TEND', 'values', 0], null),
    UF_COMPANU: get(values, ['UF_COMPANU', 'values'], []),
    UF_ACTIVITY: concat(get(values, ['UF_ACTIVITY', 'values']), get(values, ['UF_ACTIVITY_TMC', 'values'])),
    UF_TYPE_WORK: concat(get(values, ['UF_TYPE_WORK', 'values']), get(values, ['UF_TYPE_WORK_TMC', 'values'])),
    UF_ARTICLES_OF_ASSOC: get(values, ['UF_ARTICLES_OF_ASSOC', 'values', 0], null),
    UF_EGRUL: get(values,['UF_EGRUL', 'values', 0], null),
    UF_ELECTION_PROTOCOL:  get(values,['UF_ELECTION_PROTOCOL', 'values', 0], null),
    UF_NALOG_CERT: get(values,['UF_NALOG_CERT', 'values', 0], null),
    UF_OGRN_LIC: get(values,['UF_OGRN_LIC', 'values', 0], null),
    UF_DIRECTOR_ORDER: get(values,['UF_DIRECTOR_ORDER', 'values', 0], null),
    UF_LICEN_METALL: get(values,['UF_LICEN_METALL', 'values', 0], null),
    UF_LICEN_WASTE_1_4: get(values,['UF_LICEN_WASTE_1_4', 'values', 0], null),
    UF_LIC_WASTE_1_4_AKB: get(values,['UF_LIC_WASTE_1_4_AKB', 'values', 0], null),
    UF_SPEC_RECORD: get(values,['UF_SPEC_RECORD', 'values', 0], null),
    UF_APPLICATION_FORM: get(values,['UF_APPLICATION_FORM', 'values', 0], null),
    UF_EVRAZ_PD_AGREE: get(values, ['UF_EVRAZ_PD_AGREE', 'values', 0], null),
    BANK_DETAILS: get(values, ['BANK_DETAILS', 'values'], []),
    SLAVE_USERS: get(values, ['SLAVE_USERS', 'values'], []),
  },
});
