import Api from '@/api';
import {
  accountDetail,
  accountTimeLine,
  accountList,
  accountExtra,
  accountExtraPayload,
  accountSettings
} from "./selectors";

class Account extends Api {
  getAccountDetail({ id }) {
    return this.rest('/account/get.json', {
      method: 'POST',
      data: { id }
    }).then(accountDetail);
  }

  updateAccount({ id, fields }) {
    return this.rest('/account/edit.json', {
      method: 'POST',
      data: {
        item: {
          id,
          ...fields,
        },
      }
    }).then(accountDetail);
  }

  getAccountList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/account/list.json', {
      method: 'POST',
      data: { page, size, filter },
    }).then(accountList);
  }

  getAccountExtra({ accountId }) {
    return this.rest('/account/get-extra-data.json', {
      method: 'POST',
      data: { accountId },
    }).then(accountExtra);
  }

  updateAccountExtra({ item }) {
    return this.rest('/account/edit-extra-data.json', {
      method: 'POST',
      data: accountExtraPayload(item),
    }).then(accountExtra);
  }

  getAccountSettings({ accountId }) {
    return this.rest('/account/get-settings.json', {
      method: 'POST',
      data: { accountId }
    }).then(accountSettings);
  }

  updateAccountSettings({ id, fields }) {
    return this.rest('/account/edit-settings.json', {
      method: 'POST',
      data: {
        item: {
          id, ...fields
        }
      }
    }).then(accountSettings);
  }

  fetchTimeline({ id }) {
    return this.rest('/history/list.json', {
      method: 'POST',
      data: {
        filter: {
          entityId: id,
          entityType: 'ACCOUNT',
          eventType: 'CHANGE',
        }
      }
    }).then(accountTimeLine);
  }

  deleteAccount({ id }) {
    return this.rest('/account/delete.json', {
      method: 'POST',
      data: { id }
    }).then(accountDetail);
  }

  recoverAccount({ id }) {
    return this.rest('/account/recover.json', {
      method: 'POST',
      data: { id }
    }).then(accountDetail);
  }

  changeAuthor({ id, authorId }) {
    return this.rest('/account/change-author.json', {
      method: 'POST',
      data: { id, authorId }
    }).then(accountDetail);
  }
}

export default new Account();







