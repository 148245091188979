import {get, map, toString, toNumber, compact, values, pick, intersection, concat} from 'lodash-es';
import {shortName} from '@/store/inspect/selectors';

export const accountTimeLine = (result) => {
  return map(get(result, 'items'), (item) => {
    return {
      dateCreate: toString(get(item, 'dateCreate')),
      comment: toString(get(item, 'message')),
      prevStatus: {
        code:toString(get(item, 'prevStatus.code')),
        title: toString(get(item, 'prevStatus.title')),
      },
      newStatus: {
        code:toString(get(item, 'newStatus.code')),
        title: toString(get(item, 'newStatus.title')),
      },
      author: {
        id: get(item, 'author.id'),
        name: toString(get(item, 'author.name')),
        lastName: toString(get(item, 'author.lastName')),
        secondName: toString(get(item, 'author.secondName')),
        email: toString(get(item, 'author.email')),
        companyName: toString(get(item, 'author.companyName')),
        companyInn: toString(get(item, 'author.companyInn')),
        phone: toString(get(item, 'author.phone')),
        address: toString(get(item, 'author.address')),
        firstGenContact: toString(get(item, 'author.firstGenContact')),
        companyOgrn: toString(get(item, 'author.companyOgrn')),
      },
    }
  })
}

export const accountItem = (item) => {
  return {
    id: get(item, 'id'),
    type: toString(get(item, 'type')),
    status: toString(get(item, 'status')),
    commentToDelete: toString(get(item, 'commentToDelete')),
    isVerified: !!get(item, 'isVerified'),
    dateCreate: toString(get(item, 'dateCreate')),
    changeUser: toString(get(item, 'changeUser')),
    dateChange: toString(get(item, 'dateChange')),
    isActive: !!get(item, 'isActive'),
    xmlId: get(item, 'xmlId'),
    valuesVersionId: toNumber(get(item, 'valuesVersionId')),
    codeKsc: toString(get(item, 'codeKsc')),
    inn: toString(get(item, 'inn')),
    kpp: toString(get(item, 'kpp')),
    passportNumber: toString(get(item, 'passportNumber')),
    passportDate: toString(get(item, 'passportDate')),
    companyNameFull: toString(get(item, 'companyNameFull')),
    companyNameShort: toString(get(item, 'companyNameShort')),
    ogrn: toString(get(item, 'ogrn')),
    verificationLetter: compact([toString(get(item, 'verificationLetter'))]),
    companyCharter: compact([toString(get(item, 'companyCharter'))]),
    companyEgrul: compact([toString(get(item, 'companyEgrul'))]),
    companyElectronProtocol: compact([toString(get(item, 'companyElectronProtocol'))]),
    companyNalogCert: compact([toString(get(item, 'companyNalogCert'))]),
    companyOgrnCert: compact([toString(get(item, 'companyOgrnCert'))]),
    companyDirectorOrder: compact([toString(get(item, 'companyDirectorOrder'))]),
    address: {
      country: toString(get(item, 'address.country')),
      region: toString(get(item, 'address.region')),
      zipCode: toString(get(item, 'address.zipCode')),
      city: toString(get(item, 'address.city')),
      street: toString(get(item, 'address.street')),
      house: toString(get(item, 'address.house')),
      building: toString(get(item, 'address.building')),
      flat: toString(get(item, 'address.flat')),
      countryIsoCode: toString(get(item, 'address.countryIsoCode')),
      regionIsoCode: toString(get(item, 'address.regionIsoCode')),
    },
    author: {
      id: get(item, 'author.id'),
      name: toString(get(item, 'author.name')),
      lastName: toString(get(item, 'author.lastName')),
      secondName: toString(get(item, 'author.secondName')),
      fio: shortName(get(item, 'author')),
      email: toString(get(item, 'author.email')),
      companyName: toString(get(item, 'author.companyName')),
      companyInn: toString(get(item, 'author.companyInn')),
      phone: toString(get(item, 'author.phone')),
      address: toString(get(item, 'author.address')),
      firstGenContact: toString(get(item, 'author.firstGenContact')),
      companyOgrn: toString(get(item, 'author.companyOgrn')),
    },
    contactPerson: {
      email: toString(get(item, 'contactPerson.email')),
      fio: toString(get(item, 'contactPerson.fio')),
      phone: toString(get(item, 'contactPerson.phone')),
    },
    organizationLeader: {
      email: toString(get(item, 'organizationLeader.email')),
      fio: toString(get(item, 'organizationLeader.fio')),
      phone: toString(get(item, 'organizationLeader.phone')),
      position: toString(get(item, 'organizationLeader.position')),
    },
  }
}

export const accountList = (result) => {
  return {
    navCount: get(result, 'navCount'),
    navPage: get(result, 'navPage'),
    navPageCount: get(result, 'navPageCount'),
    navPageSize: get(result, 'navPageSize'),
    items: map(get(result, 'items'), (item) => accountItem(item)),
  }
};

export const accountDetail = (result) => {
  return {
    ...accountItem(get(result, 'item', {})),
  }
};

export const accountExtra = (result) => {
  const dictionary = get(result, 'dictionary');
  const item = get(result, 'item');
  const rules = {
    address: {
      text: 'Укажите корректный адрес',
      callback: (data) => {
        return !(new RegExp('#{4,}')).test(values(pick(data, ['country', 'region', 'zipCode', 'city', 'street', 'house'])).join('###') + '###');
      }
    },
  };
  return {
    values: {
      ID: get(item, 'id'),
      ACCOUNT_ID: get(item, 'accountId'),
      JUR_ADRESS_JSON: {
        label: 'Юридический адрес или адрес регистрации для ИП или физического лица',
        rules: [ rules.address ],
        hint: get(item, 'JUR_ADRESS'),
        description: 'Фактический адрес совпадает с юридическим',
        values: [
          get(item, 'jurAdress')
        ],
      },
      FACT_ADRESS_JSON: {
        label: 'Фактический адрес',
        rules: [ rules.address ],
        hint: get(item, 'FACT_ADRESS'),
        description: 'Почтовый адрес совпадает с фактическим',
        values: [
          get(item, 'factAdress')
        ],
      },
      POST_ADRESS_JSON: {
        label: 'Почтовый адрес',
        rules: [],
        hint: get(item, 'POST_ADRESS'),
        description: '',
        values: [
          get(item, 'postAdress')
        ],
      },
      PROJ_SERVICES: {
        label: 'Выполнение Работ и Услуг',
        values: [
          !!get(item, 'isProjServices', 0),
        ],
      },
      WORK_WITH_TMC: {
        label: 'Поставка ТМЦ и оборудования',
        values: [
          !!get(item, 'isWorkWithTmc', 0),
        ],
      },
      PROJ_ILLQ_TEND: {
        label: 'Приобретение Неликвидов',
        values: [
          !!get(item, 'isProjIllqTend', 0),
        ],
      },
      COMPANIES: {
        label: 'Предприятия ЕВРАЗ, с которыми организация готова работать',
        options: get(dictionary, 'companies', []),
        values: intersection(get(item, 'companies'), map(get(dictionary, 'companies'), 'value')) || [],
      },
      SUPPLY_INFO: {
        label: 'Информация для участия в закупочных процедурах',
        hint: '- Заполните информация для участияв закупочных процедурах на этп evraz.supply.com -',
        values: [
          get(item, 'supplyInfo'),
        ],
      },
      ACTIVITIES: {
        label: 'Основные виды выполняемых Работ и Услуг (максимум 24 шт.) Подписка на Оповещения о новых закупочных процедурах.',
        options: get(dictionary, 'activities'),
        values: intersection(get(item, 'activities'), map(get(dictionary, 'activities'), 'value')),
      },
      ACTIVITY_TMC: {
        label: 'Виды ТМЦ, оборудования',
        options: get(dictionary, 'activitiesTmc'),
        values: intersection(get(item, 'activities'), map(get(dictionary, 'activitiesTmc'), 'value')),
      },
      TYPE_WORK: {
        label: 'Виды работ и услуги, подписка',
        options: get(dictionary, 'activities'),
        values: intersection(get(item, 'workTypes'), map(get(dictionary, 'activities'), 'value')),
      },
      TYPE_WORK_TMC: {
        label: 'Виды ТМЦ, оборудования, подписка',
        options: get(dictionary, 'activitiesTmc'),
        values: intersection(get(item, 'workTypes'), map(get(dictionary, 'activitiesTmc'), 'value')),
      },
      ARTICLES_OF_ASSOC: {
        label: 'Устав предприятия в полном объеме',
        values: compact([
          toString(get(item, 'articlesOfAssoc', '')),
        ])
      },
      EGRUL: {
        label: 'Выписка из ЕГРЮЛ',
        values: compact([
          toString(get(item, 'egrul', '')),
        ])
      },
      ELECTION_PROTOCOL: {
        label: 'Протокол собрания о назначении руководителя предприятия',
        values: compact([
          toString(get(item, 'electionProtocol', '')),
        ])
      },
      NALOG_CERT: {
        label: 'Свидетельство о постановке на учет в налоговом органе',
        values: compact([
          toString(get(item, 'nalogCert', '')),
        ])
      },
      OGRN_LIC: {
        label: 'Свидетельство о регистрации (ОГРН)',
        values: compact([
          toString(get(item, 'ogrnLic', '')),
        ])
      },
      DIRECTOR_ORDER: {
        label: 'Приказ о назначении директора',
        values: compact([
          toString(get(item, 'directorOrder', '')),
        ])
      },
      LICEN_METALL: {
        label: 'Документ, необходимый для допуска к лотам цветного и легированного металлолома - лицензия на осуществление заготовки, переработки и реализации лома черных и/или цветных металлов',
        values: compact([
          toString(get(item, 'licenMetall', '')),
        ])
      },
      LICEN_WASTE_1_4: {
        label: 'Документ, необходимый для допуска к лотам, содержащим отходы меди - лицензия по обращению с отходами I-IV классов опасности (включая все приложения)',
        values: compact([
          toString(get(item, 'licenWaste_1_4', '')),
        ])
      },
      LIC_WASTE_1_4_AKB: {
        label: 'Документ, необходимый для допуска к лотам, содержащим лом аккумуляторных батарей - лицензия по обращению с отходами I-IV классов опасности (включая все приложения)',
        values: compact([
          toString(get(item, 'licWaste_1_4_akb', '')),
        ])
      },
      SPEC_RECORD: {
        label: 'Документ, необходимый для допуска к лотам, содержащим лом и отходы драгоценных металлов - уведомление о постановке на спец. учет',
        values: compact([
          toString(get(item, 'specRecord', '')),
        ])
      },
      APPLICATION_FORM: {
        label: 'Документ, необходимый для допуска к лотам, содержащим лом и отходы драгоценных металлов - анкета контрагента <a href="/upload/%D0%90%D0%BD%D0%BA%D0%B5%D1%82%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%82%D1%80%D0%B0%D0%B3%D0%B5%D0%BD%D1%82%D0%B0%20(%D0%B4%D0%BB%D1%8F%20%D0%B7%D0%B0%D0%BF%D0%BE%D0%BB%D0%BD%D0%B5%D0%BD%D0%B8%D1%8F).docx">Скачать шаблон анкеты</a>',
        values: compact([
          toString(get(item, 'applicationForm', '')),
        ])
      },
    },
  }
};

export const accountExtraPayload = (values) => ({
  item: {
    id: get(values, 'ID'),
    accountId: get(values, 'ACCOUNT_ID'),
    firstGenContact: get(values,['FIRST_GEN_CONTACT', 'values', 0]),
    jurAdress: get(values,['JUR_ADRESS_JSON', 'values', 0]),
    factAdress: get(values,['FACT_ADRESS_JSON', 'values', 0]),
    postAdress: get(values,['POST_ADRESS_JSON', 'values', 0]),
    isProjServices: get(values,['PROJ_SERVICES', 'values', 0], null),
    isWorkWithTmc: get(values,['WORK_WITH_TMC', 'values', 0], null),
    isProjIllqTend: get(values,['PROJ_ILLQ_TEND', 'values', 0], null),
    companies: get(values, ['COMPANIES', 'values'], []),
    supplyInfo:  get(values,['SUPPLY_INFO', 'values', 0], null),
    activities: concat(get(values, ['ACTIVITIES', 'values']), get(values, ['ACTIVITY_TMC', 'values'])),
    workTypes: concat(get(values, ['TYPE_WORK', 'values']), get(values, ['TYPE_WORK_TMC', 'values'])),
    articlesOfAssoc: toNumber(get(values, ['ARTICLES_OF_ASSOC', 'values', 0], null)) || null,
    egrul: toNumber(get(values,['EGRUL', 'values', 0], null)) || null,
    electionProtocol: toNumber(get(values,['ELECTION_PROTOCOL', 'values', 0], null)) || null,
    nalogCert: toNumber(get(values,['NALOG_CERT', 'values', 0], null)) || null,
    ogrnLic: toNumber(get(values,['OGRN_LIC', 'values', 0], null)) || null,
    directorOrder: toNumber(get(values,['DIRECTOR_ORDER', 'values', 0], null)) || null,
    licenMetall: toNumber(get(values,['LICEN_METALL', 'values', 0], null)) || null,
    licenWaste_1_4: toNumber(get(values,['LICEN_WASTE_1_4', 'values', 0], null)) || null,
    licWaste_1_4_akb: toNumber(get(values,['LIC_WASTE_1_4_AKB', 'values', 0], null)) || null,
    specRecord: toNumber(get(values,['SPEC_RECORD', 'values', 0], null)) || null,
    applicationForm: toNumber(get(values,['APPLICATION_FORM', 'values', 0], null)) || null,
  },
});

export const accountSettings = (result) => ({
  hasEdbiAccess: !!get(result, 'hasEdbiAccess'),
  isActiveAccount: !!get(result, 'isActiveAccount'),
  id: toString(get(result, 'id')),
});
